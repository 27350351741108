import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthenticatedHttpClient } from 'src/app/auth/authenticatedHttpClient';
import { catchError, throwError, firstValueFrom } from 'rxjs';
import * as Sentry from '@sentry/angular-ivy';
import { Training } from '../models/training.model';
import { UserRole } from 'src/app/auth/auth.service';
import { OnboardingRequestModalComponent } from 'src/app/shared/components/onboarding-request-modal/onboarding-request-modal.component';
import { ModalController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class TrainingsService {
  public trainings: Training[];

  private apiUrl = `${environment.carmovaApiUrl}`;

  constructor(
    private readonly http: AuthenticatedHttpClient,

    private modalController: ModalController,
  ) {}

  async fetchTrainings(currentUserRole: UserRole): Promise<Training[]> {
    const suffix =
      currentUserRole !== 'Manager' ? 'driver/trainings' : 'manager/trainings';

    try {
      this.trainings = await firstValueFrom(
        this.http.get<Training[]>(this.apiUrl + '/trainings/' + suffix).pipe(
          catchError((error) => {
            Sentry.captureException(error);

            return throwError(
              () => new Error('movacarpro_error_message_unknown'),
            );
          }),
        ),
      );

      return this.trainings;
    } catch (error) {
      throw error;
    }
  }

  async getPremiumPartnerLevelByTraining(
    trainings: Training[],
  ): Promise<number> {
    const trainingLevels = {
      levelZero: trainings.filter((t) => t.level === 0),
      levelOne: trainings.filter((t) => t.level === 1),
      levelTwo: trainings.filter((t) => t.level === 2),
      levelThree: trainings.filter((t) => t.level === 3),
    };

    const { levelZero, levelOne, levelTwo, levelThree } = trainingLevels;

    const levelZeroCompleted = levelZero.every((t) => t.isCompleted);

    const levelOneCompleted = levelOne.every((t) => t.isCompleted);

    if (levelZeroCompleted && !levelOneCompleted) {
      return 1;
    }

    const levelTwoCompleted = levelTwo.every((t) => t.isCompleted);

    if (levelZeroCompleted && levelOneCompleted && !levelTwoCompleted) {
      return 2;
    }

    const levelThreeCompleted = levelThree.every((t) => t.isCompleted);

    if (levelZeroCompleted && levelOneCompleted && levelTwoCompleted) {
      return levelThreeCompleted ? 3 : 3;
    }

    return 0;
  }

  async showOnboardingModal() {
    const modal = await this.modalController.create({
      component: OnboardingRequestModalComponent,
      backdropDismiss: false,
    });

    await modal.present();
  }
}
