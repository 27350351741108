import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-onboarding-request-modal',
  templateUrl: './onboarding-request-modal.component.html',
  styleUrls: ['./onboarding-request-modal.component.scss'],
})
export class OnboardingRequestModalComponent {
  constructor(
    private modalCtrl: ModalController,
    private router: Router,
  ) {}

  closeModal() {
    this.modalCtrl.dismiss();
    this.router.navigate(['/trainings']);
  }
}
