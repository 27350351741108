import { Component, OnInit, ViewChild } from '@angular/core';
import { IonAccordionGroup, Platform } from '@ionic/angular';
import { LanguagesService } from './services/languages.service';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import { AuthService, UserData, UserRole } from './auth/auth.service';
import { CompanyService } from './company/services/company.service';
import { ProfileService } from './profile/services/profile.service';
import { OfflineService } from './services/offline.service';
import { ToastService } from './services/toast.service';
import { VehicleInspectionService } from './vehicle-inspection/services/vehicle-inspection.service';
import { Capacitor } from '@capacitor/core';
import { App, AppInfo } from '@capacitor/app';
import { environment } from '../environments/environment';
import { FcmService } from './services/fcm.service';
import { FirebaseAnalyticsService } from './shared/services/firebase-analytics.service';
import { TrainingsService } from './trainings/services/trainings.service';
import { lastValueFrom } from 'rxjs';

declare var ZohoSalesIQ: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('accordionGroup', { static: true })
  accordionGroup: IonAccordionGroup;
  languageWasChanged = false;
  userPages: any[] = [];
  userProfilePages: any[] = [];
  userTrainingPages: any[] = [];
  syncInProgress = false;
  shouldForceErrorCount = 0;

  driverRoles = [
    UserRole.Driver,
    UserRole.SingleDriver,
    UserRole.ManagerDriver,
  ];

  managerRoles = [
    UserRole.Manager,
    UserRole.SingleDriver,
    UserRole.ManagerDriver,
  ];

  allRoles = [...this.driverRoles, ...this.managerRoles];

  pages = [
    {
      title: 'movacarpro_menu_my_transfers',
      url: '/my-transfers/',
      icon: 'reader',
      roles: this.driverRoles,
    },
    {
      title: 'movacarpro_menu_tours',
      url: '/tours/',
      icon: 'file-tray-full',
      roles: this.managerRoles,
    },
    {
      title: 'movacarpro_menu_biddings',
      url: '/biddings/',
      icon: 'ticket',
      roles: this.managerRoles,
    },
    {
      title: 'movacarpro_menu_transfers',
      url: '/transfers/',
      icon: 'car',
      roles: this.managerRoles,
    },
    {
      title: 'movacarpro_menu_billings',
      url: '/billings/',
      icon: 'receipt',
      roles: this.managerRoles,
    },
    {
      title: 'movacarpro_menu_drivers',
      url: '/drivers/',
      icon: 'people',
      roles: this.managerRoles,
    },
    {
      title: 'movacarpro_menu_reports',
      url: '/reports/',
      icon: 'bar-chart',
      roles: this.managerRoles,
    },
  ];

  profilePages = [
    {
      title: 'movacarpro_menu_personal_info',
      url: '/profile/',
      icon: 'person-circle',
      roles: this.driverRoles,
    },
    {
      title: 'movacarpro_company_profile_title',
      url: '/company/',
      icon: 'business',
      roles: this.managerRoles,
    },
  ];

  trainingPages = [
    {
      title: 'movacarpro_menu_trainings_submenu',
      url: '/trainings/',
      icon: 'barbell',
      roles: this.allRoles,
    },
    {
      title: 'movacarpro_menu_reporting_quality_title',
      url: '/quality-scoring/',
      icon: 'barbell',
      roles: this.allRoles,
    },
  ];

  UserRole = UserRole;

  constructor(
    public languagesService: LanguagesService,
    public authService: AuthService,
    private router: Router,
    private toastService: ToastService,
    private offlineService: OfflineService,
    public profileService: ProfileService,
    public companyService: CompanyService,
    private vehicleInspectionService: VehicleInspectionService,
    private firebaseAnalyticsService: FirebaseAnalyticsService,
    private platform: Platform,
    private fcm: FcmService,
    private trainingsService: TrainingsService,
  ) {
    this.platform
      .ready()
      .then(() => {
        this.fcm.initPush();
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  }

  async ngOnInit(): Promise<void> {
    await this.startFirebase();

    await this.offlineService.getNetworkStatus();

    this.authService.authSubject$.subscribe((authenticated: boolean) => {
      if (!authenticated) {
        this.router.navigate(['/login']);

        return;
      }

      this.authService.getUserData().subscribe({
        next: async (userData) => {
          if (!userData) {
            throw Error('movacarpro_error_load_user_data_failed_on_init');
          }

          this.authService.role = userData.role;

          this.userPages = this.pages.filter((p) =>
            p.roles?.includes(userData.role),
          );

          this.userProfilePages = this.profilePages.filter((p) =>
            p.roles?.includes(userData.role),
          );

          this.userTrainingPages = this.trainingPages.filter((p) =>
            p.roles?.includes(userData.role),
          );

          if (this.languageWasChanged) {
            return;
          }

          this.setLanguageToUserLanguage(userData);

          const profile = await lastValueFrom(
            this.profileService.fetchContactProfile(),
          );

          if (!profile) {
            return;
          }

          this.profileService.premiumPartnerLevel = profile.premiumPartnerLevel;

          await this.trainingsService.fetchTrainings(userData.role);

          const currentPremiumPartnerLevel =
            this.profileService.premiumPartnerLevel;

          const newPremiumPartnerLevel =
            await this.trainingsService.getPremiumPartnerLevelByTraining(
              this.trainingsService.trainings,
            );

          this.profileService.updatePremiumPartnerLevelInSalesforce(
            currentPremiumPartnerLevel,
            newPremiumPartnerLevel,
          );

          this.profileService.premiumPartnerLevel = newPremiumPartnerLevel;

          if (
            this.profileService.premiumPartnerLevel === 0 ||
            newPremiumPartnerLevel === 0
          ) {
            this.trainingsService.showOnboardingModal();
          }

          const company = await lastValueFrom(
            this.companyService.fetchCompanyProfile(),
          );

          if (!company) {
            return;
          }

          this.companyService.company = company;

          await this.vehicleInspectionService.loadInspections();
        },
        error: this.toastService.showErrorToast,
      });
    });

    await this.startZohoSalesIQ();
  }

  changeLanguage() {
    this.languageWasChanged = true;
    this.languagesService.languageChange();
  }

  toggleAccordion = () => {
    const isMobileView = window.innerWidth < 992;

    if (!isMobileView) {
      return;
    }

    const nativeEl = this.accordionGroup;
    const nativeElValue = nativeEl.value === 'legal' ? 'undefined' : 'legal';

    nativeEl.value = nativeElValue;
  };

  logout() {
    return this.authService.logout().subscribe({
      next: async () => {
        await this.router.navigate(['login']);
      },
      error: this.toastService.showErrorToast,
    });
  }

  forceAnError() {
    if (this.shouldForceErrorCount < 5) {
      this.shouldForceErrorCount++;

      return;
    }

    this.shouldForceErrorCount = 0;

    throw new Error('MovacarPRO: This error is just a test!');
  }

  forceASentryError() {
    try {
      this.forceAnError();
    } catch (err) {
      Sentry.captureException(err);
    }
  }

  private setLanguageToUserLanguage(userData: UserData) {
    this.languagesService.activeLanguage =
      this.languagesService.languages.find(
        (lan) => lan.key === userData.language.toLowerCase(),
      ) || this.languagesService.activeLanguage;

    this.languagesService.languageChange();
  }

  get stars() {
    const totalStars = 4;
    const premiumLevel = this.profileService.premiumPartnerLevel ?? 0;

    return Array.from({ length: totalStars }, (_, index) =>
      index < premiumLevel ? 'star' : 'star-outline',
    );
  }

  private async startFirebase() {
    await this.firebaseAnalyticsService.initializeFirebase();
    await this.firebaseAnalyticsService.attachToRouter();
  }

  private async startZohoSalesIQ() {
    const platform: string = Capacitor.getPlatform();

    if (platform === 'web') {
      return;
    }

    const { appKey, accessKey } = environment.zoho.salesIQ[platform];

    const info: AppInfo = await App.getInfo();
    console.log(appKey, accessKey, info.id);

    // noinspection JSUnusedLocalSymbols
    ZohoSalesIQ.init(appKey, accessKey, (success) => {
      //Use the ZohoSalesIQ.Launcher.show API if you wish to show the launcher by default.
      ZohoSalesIQ.Launcher.show(ZohoSalesIQ.Launcher.VisibilityMode.ALWAYS);
    });
  }
}
