<ion-content class="ion-padding">
  <ion-grid>
    <ion-row class="ion-align-items-center">
      <ion-col class="ion-text-center">
        <ion-img src="assets/movacar-update-delivery-box.png"></ion-img>

        <h1 class="ion-text-center">
          {{ 'movacarpro_onboarding_request_modal_title' | translate }}
        </h1>

        <p class="ion-text-center">
          {{ 'movacarpro_onboarding_request_modal_body' | translate }}
        </p>

        <ion-button expand="block" color="primary" (click)="closeModal()">
          {{ 'movacarpro_onboarding_request_modal_cta' | translate }}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

