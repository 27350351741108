import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, catchError, Observable, tap, throwError } from 'rxjs';
import { AuthenticatedHttpClient } from '../../auth/authenticatedHttpClient';
import { Profile } from '../model/profile.model';
import * as Sentry from '@sentry/angular-ivy';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService, UserRole } from 'src/app/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  public profile: Profile;

  private apiUrl = `${environment.carmovaApiUrl}/drivers/profile`;
  private apiManagerUrl = `${environment.carmovaApiUrl}/managers/profile`;

  private _profile$: BehaviorSubject<Profile | undefined> = new BehaviorSubject<
    Profile | undefined
  >(undefined);

  public premiumPartnerLevel: number;

  constructor(
    private readonly http: AuthenticatedHttpClient,
    private readonly authService: AuthService,
  ) {}

  get profile$(): BehaviorSubject<Profile | undefined> {
    return this._profile$;
  }

  fetchProfile() {
    return this.http.get<Profile>(this.apiUrl).pipe(
      tap((driver) => {
        this._profile$.next(driver);

        this.profile = driver;
      }),
      catchError((error: HttpErrorResponse) => {
        Sentry.captureException(error);

        return throwError(() => new Error('movacarpro_error_message_unknown'));
      }),
    );
  }

  updateProfile(profileUpdate: Profile): Observable<Profile> {
    return this.http.put<Profile>(this.apiUrl, profileUpdate).pipe(
      tap((updatedProfile: Profile) => {
        this._profile$.next(updatedProfile);
      }),
      catchError((error: HttpErrorResponse) => {
        Sentry.captureException(error);
        this._profile$.next(this._profile$.value);

        return throwError(
          () => new Error('movacarpro_error_saving_data_failed'),
        );
      }),
    );
  }

  fetchContactProfile() {
    const contact = this.authService.userData;
    const contactRole = contact.role;

    const apiUrl =
      contactRole === UserRole.Manager ? this.apiManagerUrl : this.apiUrl;

    return this.http.get<Profile>(apiUrl).pipe(
      tap((driver) => {
        this._profile$.next(driver);

        this.profile = driver;

        this.premiumPartnerLevel = driver.premiumPartnerLevel;
      }),
      catchError((error: HttpErrorResponse) => {
        Sentry.captureException(error);

        return throwError(() => new Error('movacarpro_error_message_unknown'));
      }),
    );
  }

  updateContactProfile(profileUpdate: Profile): Observable<Profile> {
    const apiUrl =
      profileUpdate.role === UserRole.Manager
        ? this.apiManagerUrl
        : this.apiUrl;

    return this.http
      .put<Profile>(`${apiUrl}`, profileUpdate, {
        headers: { 'content-Type': 'application/json' },
      })
      .pipe(
        tap((updatedProfile: Profile) => {
          this._profile$.next(updatedProfile);
        }),
        catchError((error: HttpErrorResponse) => {
          Sentry.captureException(error);

          return throwError(
            () => new Error('movacarpro_error_saving_data_failed'),
          );
        }),
      );
  }

  updatePremiumPartnerLevelInSalesforce(
    currentPremiumPartnerLevel: number,
    newPremiumPartnerLevel: number,
  ) {
    if (currentPremiumPartnerLevel === newPremiumPartnerLevel) {
      return;
    }

    const contact = this.profile;
    contact.premiumPartnerLevel = newPremiumPartnerLevel;

    this.updateContactProfile(contact).subscribe({
      next: () => {
        this.premiumPartnerLevel = newPremiumPartnerLevel;
      },
      error: (error: Error) => {
        Sentry.captureException(error);
      },
    });
  }
}
